<template>
  <div>
    <user/>
  </div>
</template>

<script>
import User from '@/components/User.vue'

export default {
  name: "NewUser",
  components: {
    User
  }
}
</script>

